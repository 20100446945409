import React from 'react';
import BgImage from "../Public/BgImage";
import InfoArrow from "../Public/InfoArrow";
import {Link} from "react-router-dom";


const ProjectBox = (props) => {

    return (
        <div data-aos="zoom-in" className={'col-md-4'}>
            <div className="project-item shadow-box">
                <Link className="overlay-link" target='_blank' to={props.href}></Link>
                <BgImage />
                <div className="project-img">
                    <img src={props.image} alt="Project" />
                </div>
                <div className="d-flex align-items-center justify-content-between">
                    <div className="project-info">
                        <p>{props.p}</p>
                        <h1>{props.h1}</h1>
                    </div>

                    <InfoArrow href={props.href} />
                </div>
            </div>
        </div>
    );

};



export default ProjectBox;