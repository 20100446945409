import {Route, Routes} from "react-router-dom";
import {useEffect} from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Home from "./Pages/Home";
import About from "./Pages/About";
import Blog from "./Pages/Blog";
import Contact from "./Pages/Contact";
import Credentials from "./Pages/Credentials";
import Service from "./Pages/Service";
import Works from "./Pages/Works";


function App() {

    useEffect(() => {
        AOS.init({
            duration: 1500,
            once: true,
        });
    }, []);


    return (
        <>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/about" element={<About/>}/>
                <Route path="/blog" element={<Blog/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/credential" element={<Credentials/>}/>
                <Route path="/service" element={<Service/>}/>
                <Route path="/my-works" element={<Works/>}/>
            </Routes>
        </>


);
}


export default App;
