import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";
import AboutMain from "./MainSection/AboutMain";


const About = () => {
    return (
        <main className="main-aboutpage">
            <Header />
            <AboutMain />
            <Footer />
        </main>
    );
};


export default About;