import React, {useRef} from 'react';
import BgImage from "../Public/BgImage";
import Icon3 from "../../assets/images/icon3.png";
import emailjs from '@emailjs/browser';


const ContactForm = () => {
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_1rwbdlo', 'template_zrccjlk', form.current, '-jJ1EgioXztynaQLn')
            .then((result) => {
                document.getElementById("sended").style.display = "block";
                setTimeout(() => {
                    document.getElementById("sended").style.display = "none";
                }, "5000");
                console.log(result.text)
            }, (error) => {
                document.getElementById("sended").style.display = "none";

                console.log(error.text)
            });

        e.target.reset();

    };




    return (
        <div data-aos="zoom-in" className="contact-form">
            <div className="shadow-box">
                <BgImage />
                <img src={Icon3} alt="Icon"/>
                <h1>Let’s work <span>together.</span></h1>
                <form ref={form} onSubmit={sendEmail}>
                    <div id="sended" className="alert alert-success messenger-box-contact__msg"
                         style={{ display: "none" }} role="alert">
                        Your message was sent successfully.
                    </div>
                    <div className="input-group">
                        <input type="text" name="user_name" id="full-name" placeholder="Name *"/>
                    </div>
                    <div className="input-group">
                        <input type="email" name="email" id="email" placeholder="Email *"/>
                    </div>
                    <div className="input-group">
                        <input type="text" name="subject" id="subject" placeholder="Your Subject *"/>
                    </div>
                    <div className="input-group">
                        <textarea name="message" id="message" placeholder="Your Message *"></textarea>
                    </div>
                    <div className="input-group">
                        <button className="theme-btn submit-btn" name="submit" type="submit">Send
                            Message
                        </button>
                    </div>
                </form>
            </div>
        </div>

    );
};



export default ContactForm;