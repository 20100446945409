import React from 'react';
import Image from '../../assets/images/bg1.png';

const BgImage = () => {
    return (

        <img src={Image} alt="BG" className="bg-img"/>
    );
}


export default BgImage;