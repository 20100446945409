import React from 'react';


const ProgressItem = (props) => {
    return (
        <div className="client-item">
            <h1>{props.h1}</h1>
            <p> {props.up} <br/>{props.down}</p>
        </div>
    );
}


export default ProgressItem;