import React from 'react';
import Icon2 from "../Public/Icon2";
import SectionHeading from "../Public/SectionHeading";




const AboutDetails = () => {
    return (
        <div className="about-details" data-aos="zoom-in">
            <SectionHeading heading={'Self-summary'}/>
            <div className="about-details-inner shadow-box">
                <Icon2 />
                <h1>Ahmed Ayman</h1>
                <p>I am Ahmed Ayman, a dedicated web developer with a strong background in various web technologies. My passion lies in creating websites, and I have a knack for implementing advanced techniques in React.js and the Laravel framework. </p>
            </div>
        </div>

    );
};




export default AboutDetails;