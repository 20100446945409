import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";


const Blog = () => {
    return (
        <main className="main-headerpage">
            <Header />
            <Footer />
        </main>
    );
};


export default Blog;