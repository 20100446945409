import React from 'react';
import Start2 from "./Start2";


const SectionHeading = (props) => {
    return (
        <h1 className="section-heading" data-aos="fade-up">
            <Start2 />
            {props.heading}
            <Start2 />
        </h1>
    );
};



export default SectionHeading;