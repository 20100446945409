import React from 'react';


const ContactDetails = (props) => {
    return (
        <li className="d-flex align-items-center" data-aos="zoom-in">
            <div className="icon-box shadow-box">
                <i className={props.icon}></i>
            </div>
            <div className="right">
                <span>{props.heading}</span>
                <h4>{props.d1}</h4>
                <h4>{props.d2}</h4>
            </div>
        </li>
    );
};



export default ContactDetails;