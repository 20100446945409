import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";
import HomeMain from "./MainSection/HomeMain";



const Home = () => {
    return (
        <main className="main-homepage">
            <Header/>
            <HomeMain />
            <Footer/>
        </main>
    );
}


export default Home;

