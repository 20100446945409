import React from 'react';
import {Link, NavLink} from "react-router-dom";
import './Footer.css'
import Logo from "./Public/Logo";


const Footer = () => {

    return (
        <footer className="footer-area">
            <div className="container">
                <div className="footer-content text-center">
                    <Link to="./index" className="logo">
                       <Logo />
                    </Link>
                    <ul className="footer-menu">
                        <li>
                            <NavLink to="/"> Home </NavLink>
                        </li>
                        <li>
                            <NavLink to="/about">About</NavLink>
                        </li>
                        <li>
                            <NavLink to="/works">Works</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">Contact</NavLink>
                        </li>
                    </ul>
                    <p className="copyright">
                        &copy; All rights reserved by <span style={{color: 'white'}}> Ahmed Ayman </span>
                    </p>
                </div>
            </div>
        </footer>
    );

}

export default Footer;