import React from 'react';




const Experience = (props) => {
    return (
        <li>
            <p className="date">{props.p1}</p>
            <h2>{props.h2}</h2>
            <p className="type">{props.p2}</p>
        </li>
    );
}



export default Experience;