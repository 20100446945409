import React from 'react';
import BgImage from "../Public/BgImage";
import ProgressItem from "../Public/ProgressItem";


const Goals = () => {
    return (
        <div className="col-md-6" data-aos="zoom-in">
            <div className="about-client-box info-box shadow-box">
                <BgImage />
                <div className="clients d-flex align-items-start gap-24 justify-content-center">
                    <ProgressItem h1={`+${new Date().getFullYear() - 2019}`} up={'Years'} down={'Experience'} />
                    <ProgressItem h1={'6'} up={'CLIENTS'} down={'WORLDWIDE'} />
                    <ProgressItem h1={'+18'} up={'Total'} down={'Projects'} />
                </div>
            </div>
        </div>

    );
};



export default Goals;