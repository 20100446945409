import React from 'react';
import {Link} from "react-router-dom";
import BgImage from "../Public/BgImage";
import Icon2 from "../Public/Icon2";
import ArrowImage from "../../assets/images/icon.svg";



const LWTogetherBox = () => {
    return (
            <div className="about-contact-box info-box shadow-box">
                <Link className="overlay-link" to="/contact"></Link>
                <BgImage />
                <Icon2 />
                <h1>Let's <br/>work <span>together.</span></h1>
                <Link to="#" className="about-btn">
                    <img src={ArrowImage} alt="Button"/>
                </Link>
            </div>
    );
}


export default LWTogetherBox;