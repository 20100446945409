import React from 'react';
import StarImage from "../../assets/images/star1.svg";


const BannerBox = () => {
    return (
        <div data-aos="zoom-in">

        <div className="banner shadow-box">
            <div className="marquee">
                <div>
                    <span>LATEST WORK AND <b>FEATURED</b>
                        <img src={StarImage} alt="Star"/>
                        LATEST WORK AND <b>FEATURED</b>
                        <img src={StarImage} alt="Star"/>
                        LATEST WORK AND <b>FEATURED</b>
                        <img src={StarImage} alt="Star"/>
                        LATEST WORK AND <b>FEATURED</b> LATEST WORK AND
                        <img src={StarImage} alt="Star"/>
                        LATEST WORK AND <b>FEATURED</b> LATEST WORK AND
                        <img src={StarImage} alt="Star"/>
                    </span>
                </div>
            </div>
        </div>
        </div>

    );

}


export default BannerBox;