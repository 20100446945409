import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";
import ContactMain from "./MainSection/ContactMain";


const Contact = () => {
    return (
        <main className="main-contactpage">
            <Header />
            <ContactMain />
            <Footer />
        </main>
    );
};


export default Contact;