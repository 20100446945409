import React from 'react';
import BgImage from "../Public/BgImage";
import {Link} from "react-router-dom";
import InfoArrow from "../Public/InfoArrow";


const ProfilesBox = () => {
    return (
        <div data-aos="zoom-in">
            <div className="about-profile-box info-box shadow-box h-full">
                <BgImage />
                <div className="inner-profile-icons shadow-box">
                    <Link to="https://www.facebook.com/ahmedayman.maks" target="_blank">
                        <i className="iconoir-facebook"></i>
                    </Link>
                    <Link to="https://www.linkedin.com/in/ahmed-ayman-maksoudaa/" target="_blank">
                        <i className="iconoir-linkedin"></i>
                    </Link>
                    <Link to="https://wa.me/+201090084443" target="_blank" style={{color: '#0cc144'}}>
                        Whatsapp
                    </Link>
                </div>

                <InfoArrow h4={'Stay with me'} h1={'Profiles'} href={'/contact'} />
            </div>
        </div>
    );
};



export default ProfilesBox;