import React from 'react';
import {Link} from "react-router-dom";
import BgImage from "../Public/BgImage";
import Me from "../../assets/images/me.png";
import ArrowImage from "../../assets/images/icon.svg";

const AboutMeBox = () => {
    return (
        <div className="col-md-6" data-aos="zoom-in">
            <div className="about-me-box shadow-box">
                <Link className="overlay-link" to="/about"></Link>
                <BgImage />
                <div className="img-box">
                    <img src={Me} alt="About Me"/>
                </div>
                <div className="infos">
                    <h4>A WEB Developer</h4>
                    <h1>Ahmed  </h1> <h6 style={{color: "white"}}>Ayman Abdelmaksoud .</h6>
                    <p> Backend PHP Laravel <br /> Frontend React Js <br />  Web Developer</p>
                    <Link to="#" className="about-btn">
                        <img src={ArrowImage} alt="Button"/>
                    </Link>
                </div>
            </div>
        </div>

    );
};


export default AboutMeBox;