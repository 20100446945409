import React from 'react';
import {Link} from "react-router-dom";
import ArrowImage from '../../assets/images/icon.svg';


const InfoArrow = (props) => {
    return (
        <div className="d-flex align-items-center justify-content-between">
            <div className="infos">
                <h4>{props.h4}</h4>
                <h1>{props.h1}</h1>
            </div>

            <Link to={props.href} className="about-btn">
                <img src={ArrowImage} alt="Button"/>
            </Link>

        </div>
    );
}

export default InfoArrow;