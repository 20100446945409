import React from "react";
import BG1 from "../../assets/images/bg1.png";
import Me2 from "../../assets/images/me2.png";


const ProfileImageBox = () => {
    return (
        <div data-aos="zoom-in">
            <div className="about-image-box shadow-box">
                <img src={BG1} alt="BG" className="bg-img"/>
                <div className="image-inner">
                    <img src={Me2} alt="About Me"/>
                </div>
            </div>
        </div>

    );
};



export default ProfileImageBox;