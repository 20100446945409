import React from 'react';
import ContactDetails from "./ContactDetails";
import {Link} from "react-router-dom";



const ContactInfo = () => {
    return (
        <div className="contact-infos">
            <h3 data-aos="fade-up">Contact Info</h3>
            <ul className="contact-details">
                <ContactDetails icon={'iconoir-mail'} heading={'MAIL us'} d1={'ahmeday.maks@gmail.com'} d2={'ahmed.ayman@ieee.org'} />
                <ContactDetails icon={'iconoir-phone'} heading={'Contact Us'} d1={'+201090084443'} d2={'+201019214440'} />
                <ContactDetails icon={'iconoir-pin-alt'} heading={'Location'} d1={<> Giza <br/> 6 October <br /> Sheikh Zayed City</>}/>
            </ul>

            <h3 data-aos="fade-up">Social Info</h3>
            <ul className="social-links d-flex align-center" data-aos="zoom-in">
                <li>
                    <Link to="https://www.facebook.com/ahmedayman.maks" target="_blank">
                        <i className="iconoir-facebook"></i>
                    </Link>
                </li>
                <li>
                    <Link to="https://www.linkedin.com/in/ahmed-ayman-maksoudaa/" target="_blank">
                        <i className="iconoir-linkedin"></i>
                    </Link>
                </li>
                <li>
                    <Link to="https://wa.me/+201090084443" target="_blank" style={{color: ''}}>
                        W
                    </Link>
                </li>
            </ul>
        </div>
    );
};



export default ContactInfo;