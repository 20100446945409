import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";

const Credentials = () => {
    return (
        <main className="main-credentialspage">
            <Header />
            <Footer />
        </main>
    );
};

export default Credentials;