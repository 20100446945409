import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";

const Service = () => {
    return (
        <main className="main-servicepage">
            <Header />
            <Footer />

        </main>
    );
};


export default Service;