import React from 'react';


const Logo = () => {
    return (

        <div style={{
            fontSize: '30px',
            fontFamily: 'revert',
            color: 'aliceblue',
        }}>
            WebX
        </div>
    );
}


export default Logo;