import React from 'react';
import {Link} from "react-router-dom";
import BgImage from "../Public/BgImage";
import InfoArrow from "../Public/InfoArrow";



const ServiceOfferingBox = () => {
    return (
        <div data-aos="zoom-in" className="flex-1">
            <div className="about-services-box info-box shadow-box h-full">
                <Link to="/works" className="overlay-link"></Link>
                <BgImage />
                <div className="icon-boxes">
                    <i className="iconoir-camera"></i>
                    <i className="iconoir-design-pencil"></i>
                    <i className="iconoir-color-filter"></i>
                    <i className="iconoir-dev-mode-phone"></i>
                </div>

                <InfoArrow h4={'specialization'} h1={'Services Offering'} href={'/service'} />
            </div>
        </div>
    );
};



export default ServiceOfferingBox;