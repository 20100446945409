import React from 'react';
import SectionHeading from "../../component/Public/SectionHeading";
import ProjectBox from "../../component/Boxes/ProjectBox";
import ProjectImage1 from '../../assets/images/project1.jpeg';
import alwedian from '../../assets/images/alwedian.png';
import lloydsegypt from '../../assets/images/lloydsegypt.png';
import mirror_glamour from '../../assets/images/mirror-glamour.png';
import ieee from '../../assets/images/ieee.png';
import nasaspace from '../../assets/images/nasaspace.png';
import SMB from '../../assets/images/smb-logo.png';
import SOS from '../../assets/images/sos-logo.png';
import './WorksMain.css';
import NUTRIGEIN from "../../assets/images/nutrigein-logo.jpeg";

const WorksMain = () => {
    return (<section className="projects-area">
            <SectionHeading heading={'All Projects'} />

            <div className="container">

            <div className="row">
                <ProjectBox href={'https://nutrigein.com/'} image={NUTRIGEIN} p={'Full Stack project'}
                            h1={'Nutrigein - Saudi Arabia'}/>

                <ProjectBox href={'https://new.smb.express/'} image={SMB} p={'React Js'} h1={'SMB - Saudi Arabia'}/>
                <ProjectBox href={'https://alwedian-eg.com'} image={alwedian} p={'Full Stack project'}
                            h1={'Alwedian - Egypt'}/>
                <ProjectBox href={'https://sos.inventdemo.com'} image={SOS} p={'React Js'} h1={'SOS - Saudi Arabia '}/>
                <ProjectBox href={'https://lloydsegypt-eg.com'} image={lloydsegypt} p={'Full Stack'}
                            h1={'Lloyds Egypt - Egypt'}/>
                <ProjectBox href={'https://ieee-bub.org/'} image={ieee} p={'PHP'} h1={'IEEE BUB - Egypt'}/>
                <ProjectBox href={'https://www.spaceappscairo.com/'} image={nasaspace} p={'Wordpress'}
                            h1={'Nasa Space Apps - Egypt'}/>
                <ProjectBox href={'https://mirror-glamour.com/'} image={mirror_glamour} p={'Wordpress'}
                            h1={'Mirror Glamour - Egypt'}/>
                <ProjectBox href={'https://light-dark-mode.ahmedayman.org'} image={ProjectImage1}
                            p={'Javascript Task'} h1={'Light Dark Mode'}/>

                <ProjectBox href={'https://github.com/ahmedayman220/E-commerce__'} image={ProjectImage1}
                            p={'PHP Laravel'} h1={'Multi-Vendor Ecommerce'}/>
                <ProjectBox href={'https://paint-clone.ahmedayman.org'} image={ProjectImage1} p={'Javascript'}
                            h1={'Paint Clone'}/>

                <ProjectBox href={'https://github.com/ahmedayman220/School-System1'} image={ProjectImage1}
                            p={'PHP Laravel'} h1={'School Management System'}/>

                <ProjectBox href={'https://github.com/ahmedayman220/News'} image={ProjectImage1}
                            p={'PHP Laravel'} h1={'News'}/>

                <ProjectBox href={'https://github.com/ahmedayman220/sail'} image={ProjectImage1}
                            p={'PHP Laravel'} h1={'Point Of sale'}/>

                <ProjectBox href={'https://github.com/ahmedayman220/News'} image={ProjectImage1}
                            p={'PHP Laravel'} h1={'News'}/>

                <ProjectBox href={'https://github.com/ahmedayman220/portofolio'} image={ProjectImage1}
                            p={'PHP Laravel'} h1={'Portfolio'}/>

                <ProjectBox href={'https://infinite-scroll.ahmedayman.org'} image={ProjectImage1}
                            p={'Javascript Task'} h1={'Infinite Scroll'}/>

                <ProjectBox href={'https://book-keeper.ahmedayman.org'} image={ProjectImage1}
                            p={'Javascript Task'} h1={'Book Keeper'}/>

                <ProjectBox href={'https://pong.ahmedayman.org'} image={ProjectImage1} p={'Javascript Task'}
                            h1={'Pong'}/>


                <ProjectBox href={'https://drag-drop-tasks.ahmedayman.org'} image={ProjectImage1}
                            p={'Javascript Task'} h1={'Drag Drop'}/>

            </div>
        </div>
    </section>

);
};


export default WorksMain;
