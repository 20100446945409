import React from 'react';
import './ContactMain.css'
import ContactInfo from "../../component/Contact/ContactInfo";
import ContactForm from "../../component/Contact/ContactForm";


const ContactMain = () => {
    return (
        <section className="contact-area">
            <div className="container">
                <div className="gx-row d-flex justify-content-between gap-24">
                    <ContactInfo />

                    <ContactForm />
                </div>
            </div>
        </section>
    );
}


export default ContactMain;