import React from 'react';
import {Link} from "react-router-dom";
import BgImage from "../../assets/images/bg1.png";
import InfoArrow from "../Public/InfoArrow";



const InfoBox = (props) => {
    return (
            <div data-aos="zoom-in">
                <div className={`about-${props.href}-box info-box shadow-box h-full`}>
                    <Link className="overlay-link" to={`/${props.href}`}></Link>
                    <img src={BgImage} alt="BG" className="bg-img"/>

                    <img src={props.image} alt="Sign"/>
                    <InfoArrow h4={props.h4} h1={props.h1} href={`/${props.href}`} />
                </div>
            </div>
    );
};

export default InfoBox;