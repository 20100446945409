import React from 'react';
import ProfilesBox from "../../component/Boxes/ProfilesBox";
import LWTogetherBox from "../../component/Boxes/LWTogetherBox";
import InfoBox from "../../component/Boxes/InfoBox";
import Sign from "../../assets/images/sign.png";
import Experience from "../../component/Boxes/Experience";
import BgImage from "../../component/Public/BgImage";
import AboutDetails from "../../component/Boxes/AboutDetails";
import ProfileImageBox from "../../component/Boxes/ProfileImageBox";


const AboutMain = () => {
    return (
        <section className="about-area">
            <div className="container">
                <div className="d-flex about-me-wrap align-items-start gap-24">
                    <ProfileImageBox/>
                    <AboutDetails/>
                </div>

                <div className="row mt-24">
                    <div className="col-md-6" data-aos="zoom-in">
                        <div className="about-edc-exp about-education shadow-box">
                            <BgImage/>
                            <h3>Experience</h3>
                            <ul>
                                <Experience p1={''} h2={'Frontend'}
                                            p2={'HTML - CSS - JS - JSON - AJAX - Working With  APIS - REACT JS - Angular'}/>
                                <Experience h2={'Backend'} p2={'PHP - SQL - LARAVEL - Working With  APIS'}/>

                                <Experience h2={'Another Skills'} p2={'Python - C++ - Datastructures & Algorithms'}/>

                            </ul>
                        </div>
                    </div>
                    <div className="col-md-6" data-aos="zoom-in">
                        <div className="about-edc-exp about-education shadow-box">
                            <BgImage/>
                            <ul>
                                <Experience p1={''} h2={'Years Of EXP:'}
                                            p2={`${new Date().getFullYear() - 2019} Years`}/>
                                <Experience p1={` 2019 - ${new Date().getFullYear()} `} h2={'University'}
                                            p2={'Banha Faculity of Engineering Associate\'s degree, Electrical and Electronics\n' +
                                                'Engineering'}/>

                                <Experience p1={''} h2={'Organization'}
                                            p2={<> IEEE BUB <br /> Nasa Space Apps Cairo </>}/>

                            </ul>
                        </div>
                    </div>
                </div>


                <div className="row mt-24">
                    <div className="col-md-12">
                        <div className="d-flex profile-contact-credentials-wrap gap-24">

                            <ProfilesBox/>
                            <div className="col-md-5" data-aos="zoom-in">
                                <LWTogetherBox/>
                            </div>

                            <InfoBox href={''} image={Sign} h4={'more about me'} h1={'Credentials'}/>


                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}


export default AboutMain;