import React from 'react';
import Sign from '../../assets/images/sign.png';
import MyWorks from '../../assets/images/my-works.png';
import GFonts from '../../assets/images/gfonts.png';
import './HomeMain.css'
import InfoBox from "../../component/Boxes/InfoBox";
import AboutMeBox from "../../component/Boxes/AboutMeBox";
import BannerBox from "../../component/Boxes/BannerBox";
import ServiceOfferingBox from "../../component/Boxes/ServiceOfferingBox";
import ProfilesBox from "../../component/Boxes/ProfilesBox";
import Goals from "../../component/Home/Goals";
import LWTogetherBox from "../../component/Boxes/LWTogetherBox";


const HomeMain = () => {
    return (
        <section className="about-area">
            <div className="container">
                <div className="row">
                    <AboutMeBox/>
                    <div className="col-md-6">
                        <div className="about-credentials-wrap">
                            <BannerBox/>
                            <div className="gx-row d-flex gap-24">
                                <InfoBox href={'about'} image={Sign} h4={'more about me'} h1={'credential'}/>
                                <InfoBox href={'works'} image={MyWorks} h4={'SHOWCASE'} h1={'Projects'}/>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="row mt-24">
                    <div className="col-md-12">
                        <div className="blog-service-profile-wrap d-flex gap-24">
                            <InfoBox href={'blog'} image={GFonts} h4={'Blog'} h1={'GFonts'}/>
                            <ServiceOfferingBox/>
                            <ProfilesBox/>

                        </div>
                    </div>

                </div>

                <div className="row mt-24">
                    <Goals/>
                    <div className="col-md-6" data-aos="zoom-in">
                        <LWTogetherBox/>
                    </div>

                </div>
            </div>
        </section>
    );
}


export default HomeMain;
