import React from 'react';
import image from '../../assets/images/icon2.png';



const Icon2 = () => {
    return (
        <img src={image} alt="Icon" className="star-icon" />
    );
}


export default Icon2;