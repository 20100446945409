import React from 'react';
import Image from '../../assets/images/star-2.png';

const Start2 = () => {
    return (
        <img src={Image} alt="Star"/>
);
};



export default Start2;