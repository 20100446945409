import React from 'react';
import Header from "../component/Header";
import Footer from "../component/Footer";
import WorksMain from "./MainSection/WorksMain";


const Works = () => {
    return (
        <main className="main-workspage">
            <Header />
            <WorksMain />
            <Footer />
        </main>
    );
};


export default Works;