import React from 'react';
import {useState} from 'react';
import './Header.css';
import {Link, NavLink} from "react-router-dom";
import Logo from "./Public/Logo";


const Header = () => {

    const [isMenuActive, setMenuActive] = useState(false);

    const toggleMenu = () => {
        setMenuActive(!isMenuActive);
    };


    return (

        <header className="header-area">
            <div className="container">
                <div className="gx-row d-flex align-items-center justify-content-between">
                    <Link to="/" className="logo">
                        <Logo />
                    </Link>
                    <nav className={`navbar  ${isMenuActive ? 'active' : ''} `}>
                        <ul className="menu">

                            <li>
                                <NavLink to="/" activeclassname="active"> Home </NavLink>
                            </li>
                            <li>
                                <NavLink to="/about" activeclassname="active">About</NavLink>
                            </li>
                            <li>
                                <NavLink to="/my-works" activeclassname="active">Works</NavLink>
                            </li>
                            <li>
                                <NavLink to="/contact" activeclassname="active">Contact</NavLink>
                            </li>
                        </ul>
                        <Link to="/contact" className="theme-btn">Let's talk</Link>
                    </nav>
                    <Link to="/contact" className="theme-btn">Let's talk</Link>
                    <div className="show-menu" onClick={toggleMenu}>
                        <span />
                        <span />
                        <span />
                    </div>
                </div>
            </div>
        </header>
    );
}


export default Header;

